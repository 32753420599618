import React from 'react'
import { Link } from 'gatsby'
import styles from './404.module.css'

const error = () => {
  return (
    <div className={styles.container}>
      <Link to={`/`} className={styles.warning}>
        <h1>Looks like this page doesn't exist, let's go back.</h1>
      </Link>
    </div>
  )
}

export default error
